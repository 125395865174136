var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "483",
      "height": "409",
      "viewBox": "0 0 483 409",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_4102_48972)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M194.427 107.315C193.762 108.027 193.731 109.124 194.355 109.872L199.346 115.855C200.09 116.748 201.449 116.786 202.242 115.936L209.456 108.211C210.25 107.362 210.119 106.009 209.178 105.327L202.868 100.757C202.079 100.185 200.987 100.29 200.322 101.003L194.427 107.315ZM191.387 112.348C189.514 110.103 189.606 106.814 191.602 104.677L197.497 98.3646C199.493 96.2275 202.768 95.911 205.136 97.6264L211.445 102.197C214.27 104.243 214.662 108.301 212.281 110.85L205.067 118.574C202.687 121.123 198.611 121.009 196.377 118.331L191.387 112.348Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M207.618 93.19C206.953 93.9024 206.922 94.9987 207.547 95.7471L212.537 101.73C213.282 102.623 214.64 102.661 215.434 101.811L222.648 94.0864C223.441 93.2368 223.311 91.884 222.369 91.2021L216.06 86.6318C215.27 86.06 214.179 86.1655 213.513 86.8778L207.618 93.19ZM204.578 98.2231C202.705 95.9776 202.798 92.6888 204.793 90.5518L210.688 84.2396C212.684 82.1025 215.959 81.786 218.327 83.5014L224.637 88.0717C227.461 90.1175 227.853 94.1759 225.473 96.7247L218.259 104.449C215.878 106.998 211.803 106.884 209.569 104.206L204.578 98.2231Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M189.72 89.7018C189.055 90.4141 189.024 91.5104 189.648 92.2589L194.639 98.2417C195.383 99.1344 196.742 99.1724 197.535 98.3228L204.749 90.5981C205.543 89.7485 205.412 88.3958 204.471 87.7138L198.161 83.1435C197.372 82.5717 196.28 82.6772 195.615 83.3896L189.72 89.7018ZM186.68 94.7348C184.807 92.4893 184.899 89.2005 186.895 87.0635L192.79 80.7513C194.786 78.6142 198.061 78.2978 200.429 80.0131L206.738 84.5834C209.563 86.6293 209.955 90.6876 207.574 93.2364L200.36 100.961C197.98 103.51 193.904 103.396 191.67 100.718L186.68 94.7348Z",
      "fill": "white"
    }
  })]), _c('g', {
    attrs: {
      "clip-path": "url(#clip1_4102_48972)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M410.712 301.927C420.344 308.308 433.325 305.673 439.706 296.042C446.087 286.411 443.452 273.43 433.821 267.049C424.189 260.667 411.208 263.302 404.827 272.934C398.446 282.565 401.081 295.546 410.712 301.927ZM407.824 306.287C419.863 314.263 436.089 310.97 444.065 298.931C452.042 286.891 448.748 270.665 436.709 262.689C424.67 254.712 408.444 258.006 400.467 270.045C392.491 282.084 395.785 298.31 407.824 306.287Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M417.156 300.476C420.9 298.323 425.172 294.311 428.809 288.822C432.446 283.332 434.474 277.835 434.998 273.547C435.563 268.913 434.292 267.361 433.823 267.05C433.355 266.739 431.43 266.174 427.382 268.502C423.638 270.656 419.367 274.667 415.73 280.156C412.093 285.646 410.064 291.143 409.541 295.431C408.975 300.066 410.246 301.618 410.715 301.928C411.184 302.239 413.109 302.804 417.156 300.476ZM407.827 306.288C413.846 310.276 425.192 303.75 433.169 291.71C441.145 279.671 442.732 266.678 436.712 262.69C430.692 258.702 419.346 265.229 411.37 277.268C403.393 289.307 401.807 302.3 407.827 306.288Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M442.516 301.041C443.062 300.371 443.58 299.668 444.068 298.932C444.555 298.197 445.001 297.445 445.404 296.681L402.022 267.938C401.475 268.608 400.957 269.311 400.47 270.047C399.982 270.783 399.537 271.534 399.133 272.298L442.516 301.041Z",
      "fill": "white"
    }
  })]), _c('g', {
    attrs: {
      "clip-path": "url(#clip2_4102_48972)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M94.9361 166.656L64.3746 156.158C63.3197 155.796 62.1357 156.266 61.7301 157.208L52.9164 177.684C52.5108 178.626 53.0371 179.684 54.092 180.046L84.6535 190.544C85.7084 190.906 86.8924 190.436 87.2981 189.494L96.1117 169.018C96.5174 168.076 95.991 167.018 94.9361 166.656ZM65.8436 152.746C62.6788 151.659 59.1268 153.069 57.9099 155.896L49.0962 176.372C47.8793 179.199 49.4583 182.372 52.6231 183.459L83.1846 193.956C86.3493 195.044 89.9014 193.633 91.1183 190.806L99.9319 170.33C101.149 167.503 99.5698 164.33 96.4051 163.243L65.8436 152.746Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M64.9949 160.993C65.9818 160.501 67.2284 160.817 67.7791 161.698L73.9054 171.506C74.3867 172.276 75.4156 172.63 76.351 172.346L88.2546 168.731C89.3247 168.407 90.487 168.918 90.8508 169.874C91.2145 170.83 90.6419 171.868 89.5718 172.193L77.6681 175.807C74.8621 176.659 71.7754 175.599 70.3313 173.287L64.205 163.48C63.6543 162.598 64.0079 161.485 64.9949 160.993Z",
      "fill": "white"
    }
  })]), _c('circle', {
    attrs: {
      "cx": "141.777",
      "cy": "160",
      "r": "8",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "274.777",
      "cy": "399",
      "r": "10",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "388.777",
      "cy": "10",
      "r": "10",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M164.803 333.889C155.475 327.731 149.484 320.672 146.992 312.907C131.294 306.241 121.16 290.642 121.16 273.112C121.16 249.388 139.856 230.086 162.838 230.086H215.881C238.862 230.086 257.559 249.388 257.559 273.112C257.559 296.837 238.863 316.138 215.881 316.138H174.749C174.395 319.688 174.538 323.384 175.174 327.129L177.08 338.373C177.343 339.922 175.632 341.037 174.321 340.172L164.803 333.889Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M257.556 273.112C257.556 249.388 238.86 230.086 215.878 230.086H162.835C156.853 230.086 151.167 231.404 146.02 233.758C148.234 269.377 178.491 297.199 214.555 297.199H250.395C254.913 290.322 257.556 282.03 257.556 273.112Z",
      "fill": "#B14C02"
    }
  }), _c('path', {
    attrs: {
      "d": "M274.166 303.225C275.493 297.51 275.88 291.901 275.32 286.547H223.821C194.861 286.547 170.693 263.55 170.252 234.593C169.803 205.12 193.648 181 223.019 181H289.591C318.612 181 342.759 204.12 343.099 233.14C343.355 254.919 330.447 274.361 310.342 282.607C306.014 295.713 293.702 305.056 284.111 310.595L274.231 316.3C272.87 317.086 271.228 315.869 271.583 314.339L274.166 303.225Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M341.921 176.594L337.202 182.992C335.899 184.759 333.411 185.135 331.644 183.832C329.877 182.529 329.501 180.041 330.804 178.274L335.523 171.876C336.826 170.109 339.313 169.733 341.081 171.036C342.848 172.339 343.224 174.827 341.921 176.594Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M337.932 194.25C337.605 192.08 339.098 190.054 341.268 189.726L349.129 188.539C351.3 188.211 353.325 189.704 353.653 191.875C353.98 194.045 352.487 196.071 350.317 196.399L342.457 197.586C340.286 197.914 338.261 196.421 337.932 194.25Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M319.836 180.907C322.006 180.579 323.5 178.553 323.172 176.383L321.984 168.523C321.656 166.352 319.63 164.859 317.46 165.187C315.29 165.515 313.796 167.541 314.124 169.71L315.312 177.571C315.64 179.741 317.665 181.235 319.836 180.907Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M100.751 267.804L108.701 267.804C110.897 267.804 112.676 269.583 112.676 271.779C112.676 273.975 110.897 275.754 108.701 275.754L100.751 275.754C98.5558 275.754 96.7767 273.975 96.7767 271.779C96.7767 269.583 98.5558 267.804 100.751 267.804Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M117.332 260.535C115.78 262.087 113.263 262.087 111.711 260.535L106.09 254.914C104.538 253.361 104.538 250.845 106.09 249.293C107.642 247.741 110.159 247.741 111.711 249.293L117.332 254.914C118.884 256.466 118.884 258.983 117.332 260.535Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M117.332 283.019C115.78 281.467 113.263 281.467 111.711 283.019L106.09 288.641C104.538 290.193 104.538 292.71 106.09 294.262C107.642 295.814 110.159 295.814 111.711 294.262L117.332 288.641C118.884 287.088 118.884 284.572 117.332 283.019Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M15.7775 282C15.7775 284.752 18.0337 287 20.838 287C23.6423 287 25.8984 284.752 25.8984 282C25.8984 279.248 23.6423 277 20.838 277C18.0337 277 15.7775 279.248 15.7775 282Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M308 88C308 90.752 310.256 93 313.061 93C315.865 93 318.121 90.752 318.121 88C318.121 85.248 315.865 83 313.061 83C310.256 83 308 85.248 308 88Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M136.778 57C136.778 59.752 139.034 62 141.838 62C144.642 62 146.898 59.752 146.898 57C146.898 54.248 144.642 52 141.838 52C139.034 52 136.778 54.248 136.778 57Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M295.778 10C295.778 12.752 298.034 15 300.838 15C303.642 15 305.898 12.752 305.898 10C305.898 7.248 303.642 5 300.838 5C298.034 5 295.778 7.248 295.778 10Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M453.246 87.7109L445.747 95.1356",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M445.746 87.7109L453.246 95.1356",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M424 328C424 330.752 426.256 333 429.061 333C431.865 333 434.121 330.752 434.121 328C434.121 325.248 431.865 323 429.061 323C426.256 323 424 325.248 424 328Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M386.778 73C386.778 75.752 389.034 78 391.838 78C394.642 78 396.898 75.752 396.898 73C396.898 70.248 394.642 68 391.838 68C389.034 68 386.778 70.248 386.778 73Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M1.0002 377C1.0002 379.752 3.25636 382 6.06065 382C8.86493 382 11.1211 379.752 11.1211 377C11.1211 374.248 8.86493 372 6.06065 372C3.25636 372 1.0002 374.248 1.0002 377Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M411 159C411 161.752 413.256 164 416.061 164C418.865 164 421.121 161.752 421.121 159C421.121 156.248 418.865 154 416.061 154C413.256 154 411 156.248 411 159Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M350 351C350 353.752 352.256 356 355.061 356C357.865 356 360.121 353.752 360.121 351C360.121 348.248 357.865 346 355.061 346C352.256 346 350 348.248 350 351Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M192 377C192 379.752 194.256 382 197.061 382C199.865 382 202.121 379.752 202.121 377C202.121 374.248 199.865 372 197.061 372C194.256 372 192 374.248 192 377Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M471 182C471 184.752 473.256 187 476.061 187C478.865 187 481.121 184.752 481.121 182C481.121 179.248 478.865 177 476.061 177C473.256 177 471 179.248 471 182Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_4102_48972"
    }
  }, [_c('rect', {
    attrs: {
      "width": "46.3842",
      "height": "46.3842",
      "fill": "white",
      "transform": "translate(169.777 96.8984) rotate(-46.9572)"
    }
  })]), _c('clipPath', {
    attrs: {
      "id": "clip1_4102_48972"
    }
  }, [_c('rect', {
    attrs: {
      "width": "62.7584",
      "height": "62.7584",
      "fill": "white",
      "transform": "translate(413.441 241) rotate(33.526)"
    }
  })]), _c('clipPath', {
    attrs: {
      "id": "clip2_4102_48972"
    }
  }, [_c('rect', {
    attrs: {
      "width": "48.4713",
      "height": "44.5836",
      "fill": "white",
      "transform": "matrix(0.945761 0.324862 -0.395377 0.918519 60.4062 145)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }