<template>
  <div class="section container">
    <div class="row align-items-center">
      <div class="col-12 col-md-6">
        <div class="text-wrap who-we-are-text">
          <h2 class="light-theme-title text-center text-md-left">
            {{ $t("about-us.power-of-technology.title") }}
          </h2>
          <p
            class="body-paragraph-1 text-grey-primary-main-500"
            style="white-space: pre-line"
          >
            {{ $t("about-us.power-of-technology.text") }}
          </p>
        </div>
      </div>
      <PowerOfTech class="col-12 col-md-6 d-none d-md-block" />
      <PowerOfTechMobile class="col-12 col-md-6 d-md-none" />
    </div>
    <div class="row align-items-center">
      <div class="col-12 col-md-6 order-md-2">
        <div class="text-wrap">
          <h2 class="light-theme-title text-center text-md-left">
            {{ $t("about-us.regulated-broker.title") }}
          </h2>
          <p
            class="body-paragraph-1 text-grey-primary-main-500"
            v-html="$t('about-us.regulated-broker.text')"
          ></p>
        </div>
      </div>
      <RegulatedBroker class="col-12 col-md-6 d-none d-md-block" />
      <RegulatedBrokerMobile class="col-12 col-md-6 d-md-none" />
    </div>
    <div class="row align-items-center last">
      <div class="col-12 col-md-6">
        <div class="text-wrap">
          <h2 class="light-theme-title text-center text-md-left">
            {{ $t("about-us.listed-broker.title") }}
          </h2>
          <p
            class="body-paragraph-1 text-grey-primary-main-500"
            style="white-space: pre-line"
          >
            {{ $t("about-us.listed-broker.text") }}
          </p>
        </div>
      </div>
      <ListedBroker class="col-12 col-md-6 d-none d-md-block" />
      <ListedBrokerMobile class="col-12 col-md-6 d-md-none" />
    </div>
  </div>
</template>

<script>
import PowerOfTech from "../svg/PowerOfTech.vue";
import ListedBroker from "../svg/ListedBroker.vue";
import RegulatedBroker from "../svg/RegulatedBroker.vue";
import PowerOfTechMobile from "../svg/PowerOfTechMobile.vue";
import ListedBrokerMobile from "../svg/ListedBrokerMobile.vue";
import RegulatedBrokerMobile from "../svg/RegulatedBrokerMobile.vue";
export default {
  components: {
    PowerOfTech,
    PowerOfTechMobile,
    ListedBroker,
    ListedBrokerMobile,
    RegulatedBroker,
    RegulatedBrokerMobile,
  },
};
</script>
<style lang="scss" scoped>
h2 {
  /* or 48px */

  /* Light Theme/Title Color */

  @media (max-width: 767px) {
    margin-bottom: 16px !important;
    text-align: left !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-bottom: 24px !important;
  }
  @media (min-width: 1200px) {
    margin-bottom: 24px !important;
  }
}
p {
  margin: 0 !important;
  /* or 27px */

  /* Grey/Primary-Main-500 */

  color: #616a70;
  @media (max-width: 767px) {
    margin-bottom: 64px !important;
  }
}
.text-wrap {
  margin: auto;
  /* width: 95%; */
}
@media (max-width: 767px) {
}
.section {
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    margin-top: 50px;
    gap: 80px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    gap: 140px;
    margin-top: 90px;
    /* margin-top: -13%; */
    // .who-icon {
    //   margin-left: -20%;
    // }
    // p {
    //   max-width: 385px !important;
    // }
  }
  @media (min-width: 1200px) {
    gap: 140px;
    margin-top: 102px;
    p {
      max-width: 380px !important;
    }
    // .who-icon {
    //   margin-left: -40px !important;
    // }
  }
}
.container {
  @media (min-width: 1200px) {
    width: 90%;
  }
}
</style>
