var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section container"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-12 col-md-6"
  }, [_c('div', {
    staticClass: "text-wrap who-we-are-text"
  }, [_c('h2', {
    staticClass: "light-theme-title text-center text-md-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.power-of-technology.title")) + " ")]), _c('p', {
    staticClass: "body-paragraph-1 text-grey-primary-main-500",
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.power-of-technology.text")) + " ")])])]), _c('PowerOfTech', {
    staticClass: "col-12 col-md-6 d-none d-md-block"
  }), _c('PowerOfTechMobile', {
    staticClass: "col-12 col-md-6 d-md-none"
  })], 1), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-12 col-md-6 order-md-2"
  }, [_c('div', {
    staticClass: "text-wrap"
  }, [_c('h2', {
    staticClass: "light-theme-title text-center text-md-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.regulated-broker.title")) + " ")]), _c('p', {
    staticClass: "body-paragraph-1 text-grey-primary-main-500",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('about-us.regulated-broker.text'))
    }
  })])]), _c('RegulatedBroker', {
    staticClass: "col-12 col-md-6 d-none d-md-block"
  }), _c('RegulatedBrokerMobile', {
    staticClass: "col-12 col-md-6 d-md-none"
  })], 1), _c('div', {
    staticClass: "row align-items-center last"
  }, [_c('div', {
    staticClass: "col-12 col-md-6"
  }, [_c('div', {
    staticClass: "text-wrap"
  }, [_c('h2', {
    staticClass: "light-theme-title text-center text-md-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.listed-broker.title")) + " ")]), _c('p', {
    staticClass: "body-paragraph-1 text-grey-primary-main-500",
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.listed-broker.text")) + " ")])])]), _c('ListedBroker', {
    staticClass: "col-12 col-md-6 d-none d-md-block"
  }), _c('ListedBrokerMobile', {
    staticClass: "col-12 col-md-6 d-md-none"
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }