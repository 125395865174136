var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "96",
      "height": "96",
      "viewBox": "0 0 96 96",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M88 48C88 70.0914 70.0914 88 48 88C25.9086 88 8 70.0914 8 48C8 25.9086 25.9086 8 48 8C70.0914 8 88 25.9086 88 48Z",
      "fill": "white"
    }
  }), _c('g', {
    attrs: {
      "clip-path": "url(#clip0_4014_47948)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M44.1654 60V65.75C44.1654 66.8085 45.0235 67.6667 46.082 67.6667H49.9154C50.9739 67.6667 51.832 66.8085 51.832 65.75V60H55.6654V65.75C55.6654 68.9256 53.091 71.5 49.9154 71.5H46.082C42.9064 71.5 40.332 68.9256 40.332 65.75V60H44.1654Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M53.7513 50.9636L55.187 49.8127C57.8239 47.6989 59.5013 44.4633 59.5013 40.8333C59.5013 34.4821 54.3526 29.3333 48.0013 29.3333C41.65 29.3333 36.5013 34.4821 36.5013 40.8333C36.5013 44.4633 38.1787 47.6989 40.8156 49.8127L42.2513 50.9636V56.1667C42.2513 57.2252 43.1094 58.0833 44.168 58.0833H51.8346C52.8932 58.0833 53.7513 57.2252 53.7513 56.1667V50.9636ZM57.5846 52.8036V56.1667C57.5846 59.3423 55.0103 61.9167 51.8346 61.9167H44.168C40.9923 61.9167 38.418 59.3423 38.418 56.1667V52.8036C34.9125 49.9936 32.668 45.6756 32.668 40.8333C32.668 32.365 39.5329 25.5 48.0013 25.5C56.4697 25.5 63.3346 32.365 63.3346 40.8333C63.3346 45.6756 61.0901 49.9936 57.5846 52.8036Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M45.522 41.3934C44.7735 40.6449 43.5599 40.6449 42.8114 41.3934C42.0629 42.1419 42.0629 43.3555 42.8114 44.104L46.0833 47.3759V58.082H49.9167V47.3759L53.1886 44.104C53.9371 43.3555 53.9371 42.1419 53.1886 41.3934C52.4401 40.6449 51.2266 40.6449 50.478 41.3934L48 43.8715L45.522 41.3934Z",
      "fill": "#07B53B"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_4014_47948"
    }
  }, [_c('rect', {
    attrs: {
      "width": "46",
      "height": "46",
      "fill": "white",
      "transform": "translate(25 25.5)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }