var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "225",
      "height": "174",
      "viewBox": "0 0 225 174",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M117.516 166.864C82.2542 166.864 53.5792 138.665 52.8111 103.588L156.454 81.1247L171.591 110.597C168.073 142.589 144.994 166.864 117.516 166.864Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "3.59064"
    }
  }), _c('path', {
    attrs: {
      "d": "M78.697 100.723L77.6059 99.8881L76.5148 100.723L64.3028 110.069L52.7986 101.265C52.997 74.4944 62.1833 49.6584 74.854 31.4741C87.7395 12.9814 103.899 1.79532 117.516 1.79532C123.09 1.79532 129.29 4.4823 135.542 9.61355C141.776 14.7288 147.921 22.1637 153.349 31.3602C164.203 49.7525 172.055 74.9757 172.055 102.149C172.055 104.568 171.941 106.956 171.722 109.307L170.726 110.069L158.513 100.723L157.421 99.8882L156.33 100.723L144.118 110.069L131.906 100.723L130.815 99.8882L129.724 100.723L117.512 110.069L105.301 100.723L104.209 99.8881L103.118 100.723L90.9083 110.069L78.697 100.723Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "3.59064"
    }
  }), _c('path', {
    attrs: {
      "d": "M37.6357 104.696H32.5452C31.1396 104.696 30 103.557 30 102.151C30 100.745 31.1396 99.6055 32.5452 99.6055H37.6357C39.0414 99.6055 40.181 100.745 40.181 102.151C40.181 103.557 39.0414 104.696 37.6357 104.696Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M43.1593 109.347C42.1653 108.353 40.5537 108.353 39.56 109.347L35.9603 112.946C34.9663 113.941 34.9663 115.552 35.9603 116.546C36.9543 117.54 38.566 117.54 39.5597 116.546L43.159 112.946C44.1533 111.952 44.1533 110.341 43.1593 109.347Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M43.159 94.9523C42.165 95.946 40.5533 95.946 39.5596 94.9523L35.9603 91.3527C34.9663 90.3584 34.9663 88.7471 35.9603 87.7531C36.9543 86.7594 38.566 86.7594 39.5596 87.7531L43.159 91.3527C44.153 92.3467 44.153 93.958 43.159 94.9523Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M192.362 104.688H187.272C185.866 104.688 184.727 103.549 184.727 102.143C184.727 100.737 185.866 99.5977 187.272 99.5977H192.362C193.768 99.5977 194.908 100.737 194.908 102.143C194.908 103.549 193.768 104.688 192.362 104.688Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M181.746 109.339C182.74 108.345 184.351 108.345 185.345 109.339L188.944 112.939C189.938 113.933 189.938 115.544 188.944 116.538C187.95 117.532 186.338 117.532 185.345 116.538L181.746 112.939C180.751 111.945 180.751 110.333 181.746 109.339Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M181.746 94.9445C182.74 95.9382 184.351 95.9382 185.345 94.9445L188.944 91.3449C189.938 90.3505 189.938 88.7392 188.944 87.7452C187.95 86.7516 186.338 86.7516 185.345 87.7452L181.746 91.3449C180.751 92.3389 180.751 93.9502 181.746 94.9445Z",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "43.707",
      "cy": "51.4297",
      "r": "3.64967",
      "stroke": "#07B53B",
      "stroke-width": "2.89592"
    }
  }), _c('path', {
    attrs: {
      "d": "M222.142 42.291C222.142 43.5246 221.142 44.5247 219.908 44.5247C218.675 44.5247 217.675 43.5246 217.675 42.291C217.675 41.0574 218.675 40.0573 219.908 40.0573C221.142 40.0573 222.142 41.0574 222.142 42.291Z",
      "stroke": "#07B53B",
      "stroke-width": "2.89592"
    }
  }), _c('circle', {
    attrs: {
      "cx": "70.9503",
      "cy": "167.478",
      "r": "6.27448",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "6.27448",
      "cy": "99.9112",
      "r": "6.27448",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.2635 143.605C18.2677 143.605 19.0817 142.791 19.0817 141.787C19.0817 140.783 18.2677 139.969 17.2635 139.969C16.2593 139.969 15.4453 140.783 15.4453 141.787C15.4453 142.791 16.2593 143.605 17.2635 143.605Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M186.189 20.0427C187.193 20.0427 188.008 19.2286 188.008 18.2244C188.008 17.2203 187.193 16.4062 186.189 16.4062C185.185 16.4062 184.371 17.2203 184.371 18.2244C184.371 19.2286 185.185 20.0427 186.189 20.0427Z",
      "fill": "#07B53B"
    }
  }), _c('circle', {
    attrs: {
      "cx": "218.282",
      "cy": "155.54",
      "r": "4.23501",
      "stroke": "#07B53B",
      "stroke-width": "3.36037"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }