var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "485",
      "height": "409",
      "viewBox": "0 0 485 409",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_4102_48971)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M200.973 88.5562C199.78 87.8363 198.237 88.1175 197.375 89.212L190.483 97.9611C189.454 99.2666 189.802 101.178 191.225 102.037L204.163 109.843C205.586 110.702 207.44 110.119 208.115 108.6L212.642 98.424C213.208 97.1508 212.738 95.6552 211.545 94.9353L200.973 88.5562ZM193.034 85.7923C195.621 82.5086 200.249 81.6651 203.828 83.8248L214.4 90.2039C217.979 92.3636 219.39 96.8505 217.691 100.67L213.164 110.846C211.138 115.402 205.577 117.15 201.308 114.575L188.371 106.768C184.102 104.192 183.056 98.4578 186.142 94.5414L193.034 85.7923Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M224.633 102.83C223.44 102.11 221.898 102.391 221.035 103.485L214.143 112.235C213.114 113.54 213.463 115.451 214.886 116.31L227.823 124.117C229.246 124.975 231.1 124.392 231.775 122.874L236.302 112.697C236.868 111.424 236.398 109.929 235.205 109.209L224.633 102.83ZM216.695 100.066C219.282 96.7821 223.909 95.9386 227.488 98.0982L238.06 104.477C241.639 106.637 243.05 111.124 241.351 114.943L236.824 125.12C234.798 129.675 229.237 131.424 224.968 128.848L212.031 121.041C207.762 118.466 206.717 112.731 209.802 108.815L216.695 100.066Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M224.223 76.7671C223.03 76.0472 221.487 76.3284 220.625 77.423L213.733 86.172C212.704 87.4775 213.052 89.389 214.475 90.2476L227.413 98.0541C228.836 98.9127 230.69 98.3299 231.365 96.8114L235.892 86.6349C236.458 85.3617 235.988 83.8661 234.795 83.1462L224.223 76.7671ZM216.284 74.0033C218.871 70.7196 223.499 69.8761 227.078 72.0357L237.65 78.4148C241.229 80.5745 242.64 85.0614 240.941 88.8809L236.414 99.0574C234.388 103.613 228.827 105.361 224.558 102.785L211.621 94.979C207.352 92.4032 206.306 86.6688 209.392 82.7523L216.284 74.0033Z",
      "fill": "white"
    }
  })]), _c('g', {
    attrs: {
      "clip-path": "url(#clip1_4102_48971)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M412.068 274.093C425.734 281.984 443.209 277.301 451.1 263.635C458.99 249.969 454.307 232.493 440.641 224.603C426.975 216.713 409.5 221.395 401.609 235.062C393.719 248.728 398.401 266.203 412.068 274.093ZM408.496 280.28C425.579 290.143 447.423 284.29 457.286 267.207C467.149 250.124 461.296 228.28 444.213 218.417C427.13 208.554 405.286 214.407 395.423 231.49C385.56 248.573 391.413 270.417 408.496 280.28Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M420.726 271.573C425.649 268.323 431.136 262.496 435.633 254.706C440.13 246.917 442.433 239.252 442.787 233.363C443.169 226.997 441.305 224.988 440.64 224.604C439.975 224.22 437.303 223.611 431.981 227.124C427.058 230.375 421.571 236.202 417.074 243.991C412.577 251.781 410.274 259.446 409.92 265.335C409.538 271.701 411.402 273.71 412.067 274.094C412.732 274.478 415.404 275.087 420.726 271.573ZM408.495 280.28C417.037 285.212 431.956 275.361 441.819 258.278C451.682 241.195 452.753 223.349 444.212 218.417C435.67 213.486 420.751 223.337 410.888 240.42C401.025 257.503 399.954 275.349 408.495 280.28Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M455.346 270.213C456.034 269.254 456.681 268.252 457.284 267.208C457.887 266.164 458.431 265.102 458.917 264.027L397.36 228.487C396.671 229.446 396.024 230.448 395.421 231.492C394.818 232.536 394.274 233.597 393.788 234.673L455.346 270.213Z",
      "fill": "white"
    }
  })]), _c('g', {
    attrs: {
      "clip-path": "url(#clip2_4102_48971)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M68.2899 188.019L24.5867 199.454C23.0782 199.849 22.0638 201.312 22.321 202.723L27.9096 233.382C28.1668 234.793 29.5982 235.617 31.1067 235.222L74.8099 223.787C76.3184 223.392 77.3328 221.928 77.0756 220.517L71.487 189.859C71.2298 188.448 69.7984 187.624 68.2899 188.019ZM23.6553 194.344C19.1297 195.528 16.0865 199.92 16.8581 204.153L22.4467 234.811C23.2183 239.044 27.5126 241.516 32.0382 240.331L75.7413 228.896C80.2669 227.712 83.3102 223.321 82.5385 219.088L76.9499 188.429C76.1783 184.196 71.8841 181.725 67.3585 182.909L23.6553 194.344Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M29.0507 204.605C29.8189 203.269 31.5146 202.671 32.8381 203.272L47.5612 209.947C48.7181 210.471 50.1894 210.086 51.0585 209.032L62.1182 195.61C63.1124 194.404 64.8615 194.099 66.0248 194.931C67.1882 195.762 67.3253 197.414 66.331 198.621L55.2713 212.042C52.6642 215.206 48.2501 216.361 44.7795 214.787L30.0564 208.112C28.7328 207.512 28.2826 205.942 29.0507 204.605Z",
      "fill": "white"
    }
  })]), _c('circle', {
    attrs: {
      "cx": "141.777",
      "cy": "160",
      "r": "8",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "274.777",
      "cy": "399",
      "r": "10",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "388.777",
      "cy": "10",
      "r": "10",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M164.803 333.889C155.475 327.731 149.484 320.672 146.992 312.907C131.294 306.241 121.16 290.642 121.16 273.112C121.16 249.388 139.856 230.086 162.838 230.086H215.881C238.862 230.086 257.559 249.388 257.559 273.112C257.559 296.837 238.863 316.138 215.881 316.138H174.749C174.395 319.688 174.538 323.384 175.174 327.129L177.08 338.373C177.343 339.922 175.632 341.037 174.321 340.172L164.803 333.889Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M257.556 273.112C257.556 249.388 238.86 230.086 215.878 230.086H162.835C156.853 230.086 151.167 231.404 146.02 233.758C148.234 269.377 178.491 297.199 214.555 297.199H250.395C254.913 290.322 257.556 282.03 257.556 273.112Z",
      "fill": "#B14C02"
    }
  }), _c('path', {
    attrs: {
      "d": "M274.166 303.225C275.493 297.51 275.88 291.901 275.32 286.547H223.821C194.861 286.547 170.693 263.55 170.252 234.593C169.803 205.12 193.648 181 223.019 181H289.591C318.612 181 342.759 204.12 343.099 233.14C343.355 254.919 330.447 274.361 310.342 282.607C306.014 295.713 293.702 305.056 284.111 310.595L274.231 316.3C272.87 317.086 271.228 315.869 271.583 314.339L274.166 303.225Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M341.921 176.594L337.202 182.992C335.899 184.759 333.411 185.135 331.644 183.832C329.877 182.529 329.501 180.041 330.804 178.274L335.523 171.876C336.826 170.109 339.313 169.733 341.081 171.036C342.848 172.339 343.224 174.827 341.921 176.594Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M337.932 194.25C337.605 192.08 339.098 190.054 341.268 189.726L349.129 188.539C351.3 188.211 353.325 189.704 353.653 191.875C353.98 194.045 352.487 196.071 350.317 196.399L342.457 197.586C340.286 197.914 338.261 196.421 337.932 194.25Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M319.836 180.907C322.006 180.579 323.5 178.553 323.172 176.383L321.984 168.523C321.656 166.352 319.63 164.859 317.46 165.187C315.29 165.515 313.796 167.541 314.124 169.71L315.312 177.571C315.64 179.741 317.665 181.235 319.836 180.907Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M100.751 267.804L108.701 267.804C110.897 267.804 112.676 269.583 112.676 271.779C112.676 273.975 110.897 275.754 108.701 275.754L100.751 275.754C98.5558 275.754 96.7767 273.975 96.7767 271.779C96.7767 269.583 98.5558 267.804 100.751 267.804Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M117.332 260.535C115.78 262.087 113.263 262.087 111.711 260.535L106.09 254.914C104.538 253.361 104.538 250.845 106.09 249.293C107.642 247.741 110.159 247.741 111.711 249.293L117.332 254.914C118.884 256.466 118.884 258.983 117.332 260.535Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M117.332 283.019C115.78 281.467 113.263 281.467 111.711 283.019L106.09 288.641C104.538 290.193 104.538 292.71 106.09 294.262C107.642 295.814 110.159 295.814 111.711 294.262L117.332 288.641C118.884 287.088 118.884 284.572 117.332 283.019Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M15.7775 282C15.7775 284.752 18.0337 287 20.838 287C23.6423 287 25.8984 284.752 25.8984 282C25.8984 279.248 23.6423 277 20.838 277C18.0337 277 15.7775 279.248 15.7775 282Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M308 88C308 90.752 310.256 93 313.061 93C315.865 93 318.121 90.752 318.121 88C318.121 85.248 315.865 83 313.061 83C310.256 83 308 85.248 308 88Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M136.778 57C136.778 59.752 139.034 62 141.838 62C144.642 62 146.898 59.752 146.898 57C146.898 54.248 144.642 52 141.838 52C139.034 52 136.778 54.248 136.778 57Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M295.778 10C295.778 12.752 298.034 15 300.838 15C303.642 15 305.898 12.752 305.898 10C305.898 7.248 303.642 5 300.838 5C298.034 5 295.778 7.248 295.778 10Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M453.246 87.7109L445.747 95.1356",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M445.746 87.7109L453.246 95.1356",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M424 328C424 330.752 426.256 333 429.061 333C431.865 333 434.121 330.752 434.121 328C434.121 325.248 431.865 323 429.061 323C426.256 323 424 325.248 424 328Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M386.778 73C386.778 75.752 389.034 78 391.838 78C394.642 78 396.898 75.752 396.898 73C396.898 70.248 394.642 68 391.838 68C389.034 68 386.778 70.248 386.778 73Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M1.0002 377C1.0002 379.752 3.25636 382 6.06065 382C8.86493 382 11.1211 379.752 11.1211 377C11.1211 374.248 8.86493 372 6.06065 372C3.25636 372 1.0002 374.248 1.0002 377Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M411 159C411 161.752 413.256 164 416.061 164C418.865 164 421.121 161.752 421.121 159C421.121 156.248 418.865 154 416.061 154C413.256 154 411 156.248 411 159Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M350 351C350 353.752 352.256 356 355.061 356C357.865 356 360.121 353.752 360.121 351C360.121 348.248 357.865 346 355.061 346C352.256 346 350 348.248 350 351Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M192 377C192 379.752 194.256 382 197.061 382C199.865 382 202.121 379.752 202.121 377C202.121 374.248 199.865 372 197.061 372C194.256 372 192 374.248 192 377Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M471 182C471 184.752 473.256 187 476.061 187C478.865 187 481.121 184.752 481.121 182C481.121 179.248 478.865 177 476.061 177C473.256 177 471 179.248 471 182Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_4102_48971"
    }
  }, [_c('rect', {
    attrs: {
      "width": "66.3117",
      "height": "66.3117",
      "fill": "white",
      "transform": "translate(208.258 51) rotate(31.1065)"
    }
  })]), _c('clipPath', {
    attrs: {
      "id": "clip1_4102_48971"
    }
  }, [_c('rect', {
    attrs: {
      "width": "85.7197",
      "height": "85.7197",
      "fill": "white",
      "transform": "translate(410.664 190.801) rotate(30)"
    }
  })]), _c('clipPath', {
    attrs: {
      "id": "clip2_4102_48971"
    }
  }, [_c('rect', {
    attrs: {
      "width": "67.7617",
      "height": "62.3268",
      "fill": "white",
      "transform": "matrix(0.967431 -0.253134 0.179332 0.983789 11.332 189.539)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }