var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "297",
      "height": "252",
      "viewBox": "0 0 297 252",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_4102_48973)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M119.63 66.0303C119.221 66.4686 119.202 67.1431 119.586 67.6037L122.657 71.2851C123.115 71.8344 123.951 71.8578 124.439 71.335L128.878 66.5818C129.367 66.059 129.286 65.2266 128.707 64.807L124.824 61.9948C124.339 61.643 123.667 61.7079 123.258 62.1462L119.63 66.0303ZM117.76 69.1272C116.607 67.7455 116.664 65.7218 117.892 64.4069L121.519 60.5228C122.748 59.2078 124.763 59.0131 126.22 60.0686L130.102 62.8808C131.84 64.1397 132.081 66.6369 130.617 68.2052L126.178 72.9584C124.713 74.5268 122.205 74.4565 120.83 72.8086L117.76 69.1272Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M127.748 57.3388C127.338 57.7772 127.319 58.4517 127.703 58.9123L130.774 62.5937C131.232 63.143 132.068 63.1664 132.557 62.6436L136.996 57.8904C137.484 57.3676 137.403 56.5352 136.824 56.1156L132.942 53.3034C132.456 52.9515 131.784 53.0165 131.375 53.4548L127.748 57.3388ZM125.877 60.4358C124.724 59.0541 124.781 57.0304 126.009 55.7154L129.637 51.8314C130.865 50.5164 132.88 50.3217 134.337 51.3772L138.219 54.1894C139.957 55.4483 140.199 57.9454 138.734 59.5138L134.295 64.267C132.83 65.8354 130.322 65.7651 128.948 64.1171L125.877 60.4358Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M116.736 55.1943C116.326 55.6326 116.308 56.3072 116.692 56.7678L119.762 60.4491C120.221 60.9984 121.057 61.0219 121.545 60.4991L125.984 55.7459C126.472 55.2231 126.392 54.3907 125.812 53.9711L121.93 51.1588C121.444 50.807 120.773 50.8719 120.363 51.3103L116.736 55.1943ZM114.865 58.2913C113.713 56.9096 113.769 54.8859 114.998 53.5709L118.625 49.6869C119.853 48.3719 121.868 48.1771 123.325 49.2326L127.208 52.0449C128.946 53.3037 129.187 55.8009 127.722 57.3693L123.283 62.1225C121.818 63.6908 119.311 63.6205 117.936 61.9726L114.865 58.2913Z",
      "fill": "white"
    }
  })]), _c('g', {
    attrs: {
      "clip-path": "url(#clip1_4102_48973)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M252.722 185.781C258.648 189.708 266.635 188.086 270.562 182.16C274.489 176.234 272.867 168.246 266.941 164.32C261.014 160.393 253.027 162.014 249.1 167.941C245.174 173.867 246.795 181.855 252.722 185.781ZM250.944 188.464C258.352 193.372 268.337 191.345 273.245 183.937C278.153 176.529 276.126 166.545 268.718 161.637C261.31 156.729 251.326 158.755 246.418 166.163C241.51 173.572 243.536 183.556 250.944 188.464Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M256.685 184.893C258.989 183.568 261.618 181.1 263.856 177.722C266.094 174.344 267.341 170.962 267.664 168.323C268.012 165.472 267.23 164.517 266.941 164.325C266.653 164.134 265.468 163.786 262.978 165.219C260.674 166.544 258.045 169.012 255.807 172.39C253.569 175.768 252.322 179.151 251.999 181.789C251.651 184.641 252.433 185.596 252.722 185.787C253.01 185.978 254.195 186.326 256.685 184.893ZM250.945 188.47C254.649 190.924 261.63 186.908 266.538 179.5C271.446 172.092 272.422 164.097 268.718 161.643C265.014 159.189 258.033 163.205 253.125 170.613C248.217 178.021 247.241 186.016 250.945 188.47Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M272.288 185.237C272.624 184.825 272.943 184.392 273.243 183.939C273.543 183.487 273.817 183.024 274.065 182.554L247.371 164.868C247.035 165.28 246.716 165.713 246.416 166.166C246.116 166.618 245.842 167.081 245.594 167.551L272.288 185.237Z",
      "fill": "white"
    }
  })]), _c('g', {
    attrs: {
      "clip-path": "url(#clip2_4102_48973)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M58.4156 102.548L39.6104 96.0883C38.9613 95.8654 38.2327 96.1547 37.9831 96.7345L32.5599 109.334C32.3103 109.913 32.6341 110.564 33.2832 110.787L52.0885 117.247C52.7376 117.47 53.4662 117.18 53.7158 116.601L59.139 104.001C59.3886 103.422 59.0648 102.771 58.4156 102.548ZM40.5143 93.9885C38.5669 93.3196 36.3813 94.1875 35.6325 95.9271L30.2092 108.526C29.4604 110.266 30.432 112.218 32.3794 112.887L51.1846 119.347C53.132 120.015 55.3176 119.148 56.0664 117.408L61.4897 104.809C62.2385 103.069 61.2669 101.117 59.3195 100.448L40.5143 93.9885Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M39.9887 99.0651C40.596 98.7624 41.3631 98.9568 41.7019 99.4993L45.4716 105.534C45.7678 106.008 46.4009 106.226 46.9764 106.051L54.301 103.827C54.9595 103.627 55.6747 103.942 55.8985 104.53C56.1223 105.118 55.7699 105.757 55.1115 105.957L47.7869 108.181C46.0603 108.705 44.1609 108.053 43.2723 106.63L39.5027 100.596C39.1638 100.053 39.3814 99.3679 39.9887 99.0651Z",
      "fill": "white"
    }
  })]), _c('circle', {
    attrs: {
      "cx": "87.2351",
      "cy": "98.4499",
      "r": "4.9226",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "169.075",
      "cy": "245.513",
      "r": "6.15325",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "239.224",
      "cy": "6.15325",
      "r": "6.15325",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M101.405 205.455C95.6657 201.665 91.979 197.322 90.4459 192.544C80.7863 188.442 74.5508 178.844 74.5508 168.057C74.5508 153.459 86.0548 141.582 100.196 141.582H132.835C146.976 141.582 158.48 153.459 158.48 168.057C158.48 182.655 146.976 194.532 132.835 194.532H107.525C107.308 196.716 107.396 198.991 107.787 201.295L108.96 208.214C109.121 209.167 108.068 209.853 107.262 209.321L101.405 205.455Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M158.479 168.057C158.479 153.459 146.975 141.582 132.833 141.582H100.195C96.5136 141.582 93.0149 142.393 89.8477 143.841C91.2104 165.759 109.828 182.879 132.019 182.879H154.073C156.853 178.647 158.479 173.544 158.479 168.057Z",
      "fill": "#B14C02"
    }
  }), _c('path', {
    attrs: {
      "d": "M168.698 186.587C169.515 183.07 169.753 179.619 169.408 176.325H137.72C119.9 176.325 105.029 162.174 104.758 144.356C104.481 126.221 119.153 111.379 137.227 111.379H178.19C196.047 111.379 210.905 125.605 211.115 143.462C211.272 156.863 203.329 168.826 190.958 173.9C188.295 181.965 180.719 187.714 174.818 191.122L168.738 194.632C167.901 195.116 166.891 194.367 167.109 193.426L168.698 186.587Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M210.393 108.666L207.49 112.603C206.688 113.69 205.157 113.921 204.07 113.12C202.983 112.318 202.751 110.787 203.553 109.7L206.456 105.763C207.258 104.675 208.789 104.444 209.876 105.246C210.964 106.048 211.195 107.579 210.393 108.666Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M207.935 119.532C207.734 118.197 208.653 116.95 209.988 116.748L214.824 116.018C216.16 115.816 217.407 116.735 217.608 118.071C217.81 119.406 216.891 120.653 215.556 120.854L210.719 121.585C209.383 121.787 208.137 120.868 207.935 119.532Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M196.804 111.322C198.139 111.12 199.058 109.873 198.856 108.538L198.125 103.701C197.923 102.366 196.677 101.447 195.341 101.648C194.006 101.85 193.087 103.097 193.289 104.432L194.02 109.269C194.222 110.604 195.468 111.523 196.804 111.322Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M61.9908 164.796L66.8824 164.796C68.2334 164.796 69.3281 165.891 69.3281 167.242C69.3281 168.593 68.2334 169.688 66.8824 169.688L61.9908 169.688C60.6398 169.688 59.545 168.593 59.545 167.242C59.545 165.891 60.6398 164.796 61.9908 164.796Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M72.1938 160.319C71.2386 161.274 69.69 161.274 68.7351 160.319L65.2765 156.86C64.3213 155.905 64.3213 154.356 65.2765 153.401C66.2317 152.446 67.7803 152.446 68.7351 153.401L72.1938 156.86C73.1489 157.815 73.1489 159.364 72.1938 160.319Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M72.1938 174.155C71.2386 173.2 69.69 173.2 68.7351 174.155L65.2765 177.614C64.3213 178.569 64.3213 180.118 65.2765 181.073C66.2317 182.028 67.7803 182.028 68.7351 181.073L72.1938 177.614C73.1489 176.659 73.1489 175.11 72.1938 174.155Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M9.75183 173.52C9.75183 175.189 11.12 176.553 12.8216 176.553C14.5233 176.553 15.8914 175.189 15.8914 173.52C15.8914 171.851 14.5233 170.487 12.8216 170.487C11.12 170.487 9.75183 171.851 9.75183 173.52Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.31867"
    }
  }), _c('path', {
    attrs: {
      "d": "M189.564 54.149C189.564 55.8176 190.932 57.1816 192.634 57.1816C194.336 57.1816 195.704 55.8176 195.704 54.149C195.704 52.4803 194.336 51.1164 192.634 51.1164C190.932 51.1164 189.564 52.4803 189.564 54.149Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "1.31867"
    }
  }), _c('path', {
    attrs: {
      "d": "M84.205 35.0709C84.205 36.7395 85.5731 38.1035 87.2748 38.1035C88.9764 38.1035 90.3446 36.7395 90.3446 35.0709C90.3446 33.4022 88.9764 32.0382 87.2748 32.0382C85.5731 32.0382 84.205 33.4022 84.205 35.0709Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "1.31867"
    }
  }), _c('path', {
    attrs: {
      "d": "M182.041 6.15289C182.041 7.82154 183.409 9.1855 185.111 9.1855C186.812 9.1855 188.181 7.82154 188.181 6.15289C188.181 4.48424 186.812 3.12027 185.111 3.12027C183.409 3.12027 182.041 4.48424 182.041 6.15289Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "1.31867"
    }
  }), _c('path', {
    attrs: {
      "d": "M278.895 53.9727L274.28 58.5412",
      "stroke": "#07B53B",
      "stroke-width": "1.31867",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M274.277 53.9727L278.892 58.5412",
      "stroke": "#07B53B",
      "stroke-width": "1.31867",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M260.939 201.825C260.939 203.493 262.307 204.857 264.009 204.857C265.711 204.857 267.079 203.493 267.079 201.825C267.079 200.156 265.711 198.792 264.009 198.792C262.307 198.792 260.939 200.156 260.939 201.825Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.31867"
    }
  }), _c('path', {
    attrs: {
      "d": "M238.037 44.9185C238.037 46.5872 239.405 47.9511 241.107 47.9511C242.808 47.9511 244.177 46.5872 244.177 44.9185C244.177 43.2499 242.808 41.8859 241.107 41.8859C239.405 41.8859 238.037 43.2499 238.037 44.9185Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.31867"
    }
  }), _c('path', {
    attrs: {
      "d": "M0.658078 231.977C0.658078 233.646 2.02623 235.01 3.72789 235.01C5.42954 235.01 6.7977 233.646 6.7977 231.977C6.7977 230.308 5.42954 228.944 3.72789 228.944C2.02623 228.944 0.658078 230.308 0.658078 231.977Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "1.31867"
    }
  }), _c('path', {
    attrs: {
      "d": "M252.939 97.8365C252.939 99.5051 254.307 100.869 256.009 100.869C257.711 100.869 259.079 99.5051 259.079 97.8365C259.079 96.1678 257.711 94.8039 256.009 94.8039C254.307 94.8039 252.939 96.1678 252.939 97.8365Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.31867"
    }
  }), _c('path', {
    attrs: {
      "d": "M215.408 215.977C215.408 217.646 216.776 219.01 218.478 219.01C220.18 219.01 221.548 217.646 221.548 215.977C221.548 214.308 220.18 212.944 218.478 212.944C216.776 212.944 215.408 214.308 215.408 215.977Z",
      "fill": "#07B53B",
      "stroke": "white",
      "stroke-width": "1.31867"
    }
  }), _c('path', {
    attrs: {
      "d": "M118.185 231.977C118.185 233.646 119.554 235.01 121.255 235.01C122.957 235.01 124.325 233.646 124.325 231.977C124.325 230.308 122.957 228.944 121.255 228.944C119.554 228.944 118.185 230.308 118.185 231.977Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.31867"
    }
  }), _c('path', {
    attrs: {
      "d": "M289.861 111.989C289.861 113.657 291.229 115.021 292.931 115.021C294.633 115.021 296.001 113.657 296.001 111.989C296.001 110.32 294.633 108.956 292.931 108.956C291.229 108.956 289.861 110.32 289.861 111.989Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.31867"
    }
  }), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_4102_48973"
    }
  }, [_c('rect', {
    attrs: {
      "width": "28.5414",
      "height": "28.5414",
      "fill": "white",
      "transform": "translate(104.465 59.6211) rotate(-46.9572)"
    }
  })]), _c('clipPath', {
    attrs: {
      "id": "clip1_4102_48973"
    }
  }, [_c('rect', {
    attrs: {
      "width": "38.6168",
      "height": "38.6168",
      "fill": "white",
      "transform": "translate(254.398 148.293) rotate(33.526)"
    }
  })]), _c('clipPath', {
    attrs: {
      "id": "clip2_4102_48973"
    }
  }, [_c('rect', {
    attrs: {
      "width": "29.8256",
      "height": "27.4334",
      "fill": "white",
      "transform": "matrix(0.945761 0.324862 -0.395377 0.918519 37.168 89.2227)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }