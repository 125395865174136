<script>
import Hero from "./components/sections/Hero.vue";
import OurInfo from "./components/sections/OurInfo.vue";
import HowDoWeDoThat from "./components/sections/HowDoWeDoThat.vue";
import WhatWeBelieve from "./components/sections/WhatWeBelieve.vue";
import JoinUs from "./components/sections/JoinUs.vue";
export default {
  components: {
    Hero,
    OurInfo,
    HowDoWeDoThat,
    WhatWeBelieve,
    JoinUs,
  },
  metaInfo: {
    title: 'NOZAX: Trustworthy, Innovative, Client-centric',
    meta: [
      { name: 'description', content: 'NOZAX is a multi-asset neo‑broker that allows customers to invest in Stocks, FX, Indices, and Commodities. Regulated and listed in Montenegro. ' },
    ]
  }
};
</script>

<template>
  <div class="position-relative">
    <Hero />
    <OurInfo />
    <HowDoWeDoThat />
    <WhatWeBelieve />
    <JoinUs />
  </div>
</template>
