var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "section1 container"
  }, [_c('div', {
    staticClass: "row w-100",
    staticStyle: {
      "margin": "0 !important",
      "padding": "0 !important"
    }
  }, [_c('div', {
    staticClass: "col text-center"
  }, [_c('h2', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.what-we-believe.title")) + " ")])])])]), _c('div', {
    staticStyle: {
      "background-color": "#07b53b"
    }
  }, [_c('div', {
    staticClass: "section container",
    staticStyle: {
      "padding": "50px 0"
    }
  }, [_c('div', {
    staticClass: "row d-flex justify-content-center w-100 greenRow",
    staticStyle: {
      "margin": "0px !important"
    }
  }, [_c('div', {
    staticClass: "col-12 col-md-4 d-flex flex-column align-items-center"
  }, [_c('div', {
    staticClass: "circle-icon mb-4 d-flex justify-content-center align-items-center"
  }, [_c('InnovativeTechIcon')], 1), _c('p', {
    staticClass: "subtitle-bodyLarge"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.what-we-believe.tech")) + " ")])]), _c('div', {
    staticClass: "col-12 col-md-4 d-flex flex-column align-items-center"
  }, [_c('div', {
    staticClass: "circle-icon mb-4 d-flex justify-content-center align-items-center"
  }, [_c('SimpleProcessIcon')], 1), _c('p', {
    staticClass: "subtitle-bodyLarge"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.what-we-believe.process")) + " ")])]), _c('div', {
    staticClass: "col-12 col-md-4 d-flex flex-column align-items-center"
  }, [_c('div', {
    staticClass: "circle-icon mb-4 d-flex justify-content-center align-items-center"
  }, [_c('BusinessTransIcon')], 1), _c('p', {
    staticClass: "subtitle-bodyLarge"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.what-we-believe.transparency")) + " ")])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }