var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "greenBanner"
  }, [_c('div', {
    staticClass: "container",
    staticStyle: {
      "padding": "0 !important"
    }
  }, [_c('div', {
    staticClass: "hero-custom"
  }, [_c('div', {
    staticClass: "hero-custom__content"
  }, [_c('h1', {
    staticClass: "hero-custom__title"
  }, [_vm._v(_vm._s(_vm.$t("about-us.hero.title")) + ".")]), _c('h2', {
    staticClass: "hero-custom__text hero-subtitle"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.hero.body")) + " ")])]), _c('div', {
    staticClass: "d-none d-xl-block"
  }, [_c('Hero')], 1), _c('div', {
    staticClass: "d-none d-md-block d-xl-none"
  }, [_c('HeroTablet', {
    staticStyle: {
      "transform": "translate(-120px, 65px)"
    }
  })], 1), _c('div', {
    staticClass: "d-block d-md-none",
    staticStyle: {
      "margin-bottom": "62px"
    }
  }, [_c('HeroMobile')], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }