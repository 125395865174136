var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "position-relative"
  }, [_c('Hero'), _c('OurInfo'), _c('HowDoWeDoThat'), _c('WhatWeBelieve'), _c('JoinUs')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }