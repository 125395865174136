var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "313",
      "height": "321",
      "viewBox": "0 0 313 321",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M270.995 151.637H263.045C260.849 151.637 259.07 149.858 259.07 147.662C259.07 145.467 260.849 143.688 263.045 143.688H270.995C273.19 143.688 274.969 145.467 274.969 147.662C274.969 149.858 273.19 151.637 270.995 151.637Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M254.414 158.906C255.966 157.354 258.483 157.354 260.035 158.906L265.656 164.527C267.208 166.08 267.208 168.596 265.656 170.149C264.104 171.7 261.587 171.7 260.035 170.149L254.414 164.527C252.862 162.975 252.862 160.459 254.414 158.906Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M254.414 136.422C255.966 137.974 258.483 137.974 260.035 136.422L265.656 130.801C267.208 129.248 267.208 126.732 265.656 125.179C264.104 123.628 261.587 123.628 260.035 125.179L254.414 130.801C252.862 132.353 252.862 134.869 254.414 136.422Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M26.5796 151.637H34.5291C36.7248 151.637 38.5039 149.858 38.5039 147.662C38.5039 145.467 36.7248 143.688 34.5291 143.688H26.5796C24.384 143.688 22.6049 145.467 22.6049 147.662C22.6049 149.858 24.384 151.637 26.5796 151.637Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M43.16 158.906C41.6077 157.354 39.0909 157.354 37.5392 158.906L31.9183 164.527C30.3661 166.08 30.3661 168.596 31.9183 170.149C33.4706 171.7 35.9874 171.7 37.5392 170.149L43.16 164.527C44.7123 162.975 44.7123 160.459 43.16 158.906Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M43.16 136.422C41.6077 137.974 39.0909 137.974 37.5392 136.422L31.9183 130.801C30.3661 129.248 30.3661 126.732 31.9183 125.179C33.4706 123.628 35.9874 123.628 37.5392 125.179L43.16 130.801C44.7123 132.353 44.7123 134.869 43.16 136.422Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M64.3789 32.7266H234.094V275.12H64.3789V32.7266Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M213.606 307.844H84.8722C71.6897 307.844 60.9648 297.119 60.9648 283.937V23.9074C60.9648 10.7249 71.6897 0 84.8722 0H213.607C226.789 0 237.514 10.7249 237.514 23.9074V283.937C237.513 297.119 226.789 307.844 213.606 307.844V307.844ZM84.8722 6.83069C75.4561 6.83069 67.7955 14.4913 67.7955 23.9074V283.937C67.7955 293.353 75.4561 301.013 84.8722 301.013H213.607C223.023 301.013 230.683 293.353 230.683 283.937V23.9074C230.683 14.4913 223.023 6.83069 213.607 6.83069H84.8722Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M161.178 22.1119H137.294C135.408 22.1119 133.879 20.5825 133.879 18.6966C133.879 16.8106 135.408 15.2812 137.294 15.2812H161.178C163.064 15.2812 164.594 16.8106 164.594 18.6966C164.594 20.5825 163.064 22.1119 161.178 22.1119Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M177.12 22.1147C179.007 22.1147 180.537 20.585 180.537 18.698C180.537 16.811 179.007 15.2812 177.12 15.2812C175.233 15.2812 173.703 16.811 173.703 18.698C173.703 20.585 175.233 22.1147 177.12 22.1147Z",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "263.052",
      "cy": "85.572",
      "r": "9.79853",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "303.105",
      "cy": "167.832",
      "r": "7.14799",
      "stroke": "#07B53B",
      "stroke-width": "4"
    }
  }), _c('circle', {
    attrs: {
      "cx": "31.23",
      "cy": "54.773",
      "r": "7.14799",
      "stroke": "#07B53B",
      "stroke-width": "4"
    }
  }), _c('circle', {
    attrs: {
      "cx": "34.2485",
      "cy": "255.26",
      "r": "2",
      "stroke": "#07B53B",
      "stroke-width": "2.87973"
    }
  }), _c('circle', {
    attrs: {
      "cx": "3.43987",
      "cy": "124.436",
      "r": "2",
      "stroke": "#07B53B",
      "stroke-width": "2.87973"
    }
  }), _c('circle', {
    attrs: {
      "cx": "277.799",
      "cy": "208.858",
      "r": "2",
      "stroke": "#07B53B",
      "stroke-width": "2.87973"
    }
  }), _c('circle', {
    attrs: {
      "cx": "23.3649",
      "cy": "222.752",
      "r": "9.79853",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M137.14 148.94C137.14 144.696 137.938 140.923 139.534 137.662C141.13 134.4 143.373 131.846 146.185 129.96C148.997 128.113 152.266 127.17 155.914 127.17C159.639 127.17 162.869 128.113 165.72 129.96C168.532 131.807 170.775 134.4 172.371 137.701C173.967 141.002 174.765 144.735 174.765 148.94C174.765 149.097 174.727 149.254 174.727 149.412H181.264C181.264 149.254 181.264 149.097 181.264 148.94C181.264 144.971 180.656 141.277 179.402 137.858C178.148 134.479 176.4 131.492 174.119 128.977C171.839 126.423 169.178 124.458 166.062 123.083C162.983 121.668 159.601 121 155.876 121C152.228 121 148.845 121.707 145.767 123.083C142.688 124.497 139.99 126.462 137.71 128.977C135.429 131.532 133.681 134.479 132.465 137.858C131.249 141.238 130.641 144.932 130.641 148.94C130.641 149.097 130.641 149.254 130.641 149.412H137.102C137.178 149.254 137.14 149.097 137.14 148.94Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M163.169 149.41C163.093 153.418 162.333 156.994 160.774 160.177C159.178 163.478 156.936 166.033 154.123 167.919C151.311 169.766 148.042 170.709 144.318 170.709C140.669 170.709 137.401 169.766 134.588 167.919C131.776 166.072 129.534 163.478 127.937 160.177C126.379 157.034 125.619 153.418 125.543 149.41H119.082C119.12 153.222 119.728 156.759 120.906 160.02C122.122 163.4 123.871 166.386 126.151 168.901C128.431 171.456 131.092 173.42 134.208 174.796C137.287 176.21 140.669 176.879 144.318 176.879C148.042 176.879 151.425 176.171 154.503 174.796C157.582 173.381 160.28 171.416 162.561 168.901C164.841 166.347 166.589 163.4 167.843 160.02C169.06 156.759 169.668 153.222 169.706 149.41H163.169Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M166.287 143.674C168.113 143.674 169.593 142.143 169.593 140.255C169.593 138.367 168.113 136.836 166.287 136.836C164.461 136.836 162.98 138.367 162.98 140.255C162.98 142.143 164.461 143.674 166.287 143.674Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }