var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "96",
      "height": "96",
      "viewBox": "0 0 96 96",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M88 48C88 70.0914 70.0914 88 48 88C25.9086 88 8 70.0914 8 48C8 25.9086 25.9086 8 48 8C70.0914 8 88 25.9086 88 48Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M30.75 35.0846C30.75 30.8505 34.1825 27.418 38.4167 27.418H54.4077C56.441 27.418 58.3911 28.2257 59.8288 29.6635L63.0045 32.8391C64.4423 34.2769 65.25 36.2269 65.25 38.2603V61.918C65.25 66.1521 61.8175 69.5846 57.5833 69.5846H38.4167C34.1825 69.5846 30.75 66.1521 30.75 61.918V35.0846ZM61.4167 40.8346V61.918C61.4167 64.0351 59.7004 65.7513 57.5833 65.7513H38.4167C36.2996 65.7513 34.5833 64.0351 34.5833 61.918V35.0846C34.5833 32.9675 36.2996 31.2513 38.4167 31.2513H51.8333V35.0846C51.8333 38.2603 54.4077 40.8346 57.5833 40.8346H61.4167ZM61.204 37.0013C61.0158 36.46 60.7068 35.9626 60.2939 35.5497L57.1183 32.3741C56.7054 31.9611 56.2079 31.6522 55.6667 31.4639V35.0846C55.6667 36.1432 56.5248 37.0013 57.5833 37.0013H61.204Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M55.104 47.1434C55.8525 47.8919 55.8525 49.1055 55.104 49.854L47.4373 57.5207C46.6888 58.2692 45.4752 58.2692 44.7267 57.5207L40.8934 53.6873C40.1449 52.9388 40.1449 51.7252 40.8934 50.9767C41.6419 50.2282 42.8555 50.2282 43.604 50.9767L46.082 53.4548L52.3934 47.1434C53.1419 46.3949 54.3555 46.3949 55.104 47.1434Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }