var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "208",
      "height": "213",
      "viewBox": "0 0 208 213",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M179.77 100.59H174.496C173.04 100.59 171.859 99.4096 171.859 97.9531C171.859 96.4966 173.04 95.3164 174.496 95.3164H179.77C181.226 95.3164 182.406 96.4966 182.406 97.9531C182.406 99.4096 181.226 100.59 179.77 100.59Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M168.772 105.409C169.802 104.379 171.472 104.379 172.501 105.409L176.23 109.138C177.259 110.168 177.259 111.837 176.23 112.867C175.2 113.896 173.53 113.896 172.501 112.867L168.772 109.138C167.743 108.108 167.743 106.439 168.772 105.409Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M168.772 90.4957C169.802 91.5251 171.472 91.5251 172.501 90.4957L176.23 86.7667C177.259 85.7366 177.259 84.0674 176.23 83.0377C175.2 82.0083 173.53 82.0083 172.501 83.0377L168.772 86.7667C167.743 87.7964 167.743 89.4656 168.772 90.4957Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.6367 100.59H22.9102C24.3667 100.59 25.5469 99.4096 25.5469 97.9531C25.5469 96.4966 24.3667 95.3164 22.9102 95.3164H17.6367C16.1802 95.3164 15 96.4966 15 97.9531C15 99.4096 16.1802 100.59 17.6367 100.59Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M28.634 105.409C27.6042 104.379 25.9347 104.379 24.9053 105.409L21.1766 109.138C20.1469 110.168 20.1469 111.837 21.1766 112.867C22.2063 113.896 23.8759 113.896 24.9053 112.867L28.634 109.138C29.6637 108.108 29.6637 106.439 28.634 105.409Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M28.634 90.4957C27.6042 91.5251 25.9347 91.5251 24.9053 90.4957L21.1766 86.7667C20.1469 85.7366 20.1469 84.0674 21.1766 83.0377C22.2063 82.0083 23.8759 82.0083 24.9053 83.0377L28.634 86.7667C29.6637 87.7964 29.6637 89.4656 28.634 90.4957Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M42.707 21.7109H155.29V182.506H42.707V21.7109Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M141.698 204.213H56.3007C47.5559 204.213 40.4414 197.098 40.4414 188.354V15.8593C40.4414 7.11449 47.5559 0 56.3007 0H141.699C150.444 0 157.558 7.11449 157.558 15.8593V188.354C157.558 197.098 150.443 204.213 141.698 204.213ZM56.3007 4.53124C50.0544 4.53124 44.9726 9.61302 44.9726 15.8593V188.354C44.9726 194.6 50.0544 199.682 56.3007 199.682H141.699C147.945 199.682 153.027 194.6 153.027 188.354V15.8593C153.027 9.61302 147.945 4.53124 141.699 4.53124H56.3007Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M106.922 14.668H91.0781C89.827 14.668 88.8125 13.6534 88.8125 12.4023C88.8125 11.1513 89.827 10.1367 91.0781 10.1367H106.922C108.173 10.1367 109.188 11.1513 109.188 12.4023C109.188 13.6534 108.173 14.668 106.922 14.668Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M117.493 14.6698C118.745 14.6698 119.76 13.655 119.76 12.4032C119.76 11.1515 118.745 10.1367 117.493 10.1367C116.241 10.1367 115.227 11.1515 115.227 12.4032C115.227 13.655 116.241 14.6698 117.493 14.6698Z",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "174.5",
      "cy": "56.7656",
      "r": "6.5",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "201.068",
      "cy": "111.334",
      "r": "4.56846",
      "stroke": "#07B53B",
      "stroke-width": "3"
    }
  }), _c('circle', {
    attrs: {
      "cx": "20.7169",
      "cy": "36.3341",
      "r": "4.56846",
      "stroke": "#07B53B",
      "stroke-width": "3"
    }
  }), _c('circle', {
    attrs: {
      "cx": "22.7194",
      "cy": "169.333",
      "r": "1.71785",
      "stroke": "#07B53B",
      "stroke-width": "1.12807"
    }
  }), _c('circle', {
    attrs: {
      "cx": "2.28189",
      "cy": "82.5475",
      "r": "1.71785",
      "stroke": "#07B53B",
      "stroke-width": "1.12807"
    }
  }), _c('circle', {
    attrs: {
      "cx": "184.282",
      "cy": "138.548",
      "r": "1.71785",
      "stroke": "#07B53B",
      "stroke-width": "1.12807"
    }
  }), _c('circle', {
    attrs: {
      "cx": "15.5",
      "cy": "147.766",
      "r": "6.5",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M90.9792 98.8C90.9792 95.9847 91.5086 93.4821 92.5675 91.3185C93.6264 89.1548 95.1139 87.4604 96.9795 86.2091C98.8452 84.9839 101.013 84.3583 103.434 84.3583C105.904 84.3583 108.047 84.9839 109.938 86.2091C111.804 87.4343 113.291 89.1548 114.35 91.3445C115.409 93.5343 115.939 96.0107 115.939 98.8C115.939 98.9043 115.913 99.0085 115.913 99.1128H120.25C120.25 99.0085 120.25 98.9043 120.25 98.8C120.25 96.1671 119.847 93.7167 119.015 91.4488C118.183 89.207 117.023 87.2258 115.51 85.5574C113.997 83.863 112.233 82.5596 110.165 81.6472C108.123 80.7088 105.879 80.2656 103.409 80.2656C100.988 80.2656 98.7444 80.7348 96.7022 81.6472C94.6601 82.5857 92.87 83.8891 91.3573 85.5574C89.8446 87.2519 88.6849 89.207 87.8781 91.4488C87.0714 93.6907 86.668 96.1411 86.668 98.8C86.668 98.9043 86.668 99.0085 86.668 99.1128H90.954C91.0044 99.0085 90.9792 98.9043 90.9792 98.8Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M108.246 99.1133C108.195 101.772 107.691 104.144 106.657 106.256C105.598 108.446 104.111 110.14 102.245 111.391C100.379 112.617 98.2113 113.242 95.7405 113.242C93.3202 113.242 91.152 112.617 89.2864 111.391C87.4207 110.166 85.9332 108.446 84.8743 106.256C83.8406 104.17 83.3364 101.772 83.286 99.1133H79C79.0252 101.642 79.4286 103.988 80.2102 106.152C81.0169 108.393 82.1767 110.375 83.6894 112.043C85.2021 113.737 86.9669 115.041 89.0342 115.953C91.0764 116.892 93.3202 117.335 95.7405 117.335C98.2113 117.335 100.455 116.866 102.497 115.953C104.539 115.015 106.329 113.711 107.842 112.043C109.355 110.349 110.515 108.393 111.347 106.152C112.153 103.988 112.557 101.642 112.582 99.1133H108.246Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M110.314 95.3054C111.526 95.3054 112.508 94.29 112.508 93.0374C112.508 91.7849 111.526 90.7695 110.314 90.7695C109.103 90.7695 108.121 91.7849 108.121 93.0374C108.121 94.29 109.103 95.3054 110.314 95.3054Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }