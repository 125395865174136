var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section text-center"
  }, [_c('h3', {
    staticClass: "light-them-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.join-us.title")) + " "), _c('span', {
    staticStyle: {
      "color": "#07b53b !important"
    }
  }, [_vm._v(_vm._s(_vm.$t("about-us.join-us.title2")))])]), _c('b-button', {
    staticClass: "bt",
    attrs: {
      "variant": "primary"
    }
  }, [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": "/company/careers"
    }
  }, [_c('p', {
    staticClass: "button-large text-white-button text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.join-us.see-careers")) + " ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }