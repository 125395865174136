var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "96",
      "height": "96",
      "viewBox": "0 0 96 96",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M88 48C88 70.0914 70.0914 88 48 88C25.9086 88 8 70.0914 8 48C8 25.9086 25.9086 8 48 8C70.0914 8 88 25.9086 88 48Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M42.2513 60H46.0846V63.8333H49.918V60H53.7513V63.8333H55.668C56.7265 63.8333 57.5846 64.6915 57.5846 65.75C57.5846 66.8085 56.7265 67.6667 55.668 67.6667H40.3346C39.2761 67.6667 38.418 66.8085 38.418 65.75C38.418 64.6915 39.2761 63.8333 40.3346 63.8333H42.2513V60Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M32.6654 37L32.6654 52.3333C32.6654 54.4504 34.3816 56.1667 36.4987 56.1667L59.4987 56.1667C61.6158 56.1667 63.332 54.4504 63.332 52.3333L63.332 37C63.332 34.8829 61.6158 33.1667 59.4987 33.1667L36.4987 33.1667C34.3816 33.1667 32.6654 34.8829 32.6654 37ZM28.832 52.3333C28.832 56.5675 32.2645 60 36.4987 60L59.4987 60C63.7329 60 67.1654 56.5675 67.1654 52.3333L67.1654 37C67.1654 32.7658 63.7329 29.3333 59.4987 29.3333L36.4987 29.3333C32.2645 29.3333 28.832 32.7658 28.832 37L28.832 52.3333Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M38.4167 37C37.3581 37 36.5 37.8581 36.5 38.9167C36.5 39.9752 37.3581 40.8333 38.4167 40.8333H51.8333C52.8919 40.8333 53.75 39.9752 53.75 38.9167C53.75 37.8581 52.8919 37 51.8333 37H38.4167Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M38.4167 44.6667C37.3581 44.6667 36.5 45.5248 36.5 46.5833C36.5 47.6419 37.3581 48.5 38.4167 48.5H42.25C43.3085 48.5 44.1667 47.6419 44.1667 46.5833C44.1667 45.5248 43.3085 44.6667 42.25 44.6667H38.4167Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }