<template>
  <div>
    <div class="section1 container">
      <div
        class="row w-100"
        style="margin: 0 !important; padding: 0 !important"
      >
        <div class="col text-center">
          <h2 class="light-theme-title">
            {{ $t("about-us.what-we-believe.title") }}
          </h2>
        </div>
      </div>
    </div>
    <div style="background-color: #07b53b">
      <div class="section container" style="padding: 50px 0">
        <div
          class="row d-flex justify-content-center w-100 greenRow"
          style="margin: 0px !important"
        >
          <div class="col-12 col-md-4 d-flex flex-column align-items-center">
            <div
              class="circle-icon mb-4 d-flex justify-content-center align-items-center"
            >
              <InnovativeTechIcon />
            </div>
            <p class="subtitle-bodyLarge">
              {{ $t("about-us.what-we-believe.tech") }}
            </p>
          </div>
          <div class="col-12 col-md-4 d-flex flex-column align-items-center">
            <div
              class="circle-icon mb-4 d-flex justify-content-center align-items-center"
            >
              <SimpleProcessIcon />
            </div>
            <p class="subtitle-bodyLarge">
              {{ $t("about-us.what-we-believe.process") }}
            </p>
          </div>
          <div class="col-12 col-md-4 d-flex flex-column align-items-center">
            <div
              class="circle-icon mb-4 d-flex justify-content-center align-items-center"
            >
              <BusinessTransIcon />
            </div>
            <p class="subtitle-bodyLarge">
              {{ $t("about-us.what-we-believe.transparency") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InnovativeTechIcon from "../svg/desktop-tablet-mobile/InnovativeTechIcon.vue";
import SimpleProcessIcon from "../svg/desktop-tablet-mobile/SimpleProcessIcon.vue";
import BusinessTransIcon from "../svg/desktop-tablet-mobile/BusinessTransIcon.vue";
export default {
  components: {
    InnovativeTechIcon,
    SimpleProcessIcon,
    BusinessTransIcon,
  },
};
</script>

<style lang="scss" scoped>
.subtitle-bodyLarge light-theme-title {
  @media (max-width: 767px) {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
}
.subtitle-bodyLarge {
  @media (min-width: 1200px) {
    //font-family: "Open Sans";
    font-size: 18px !important;
  }
}
.row {
  @media (max-width: 767px) {
  }
}
.circle-icon {
  width: 80px;
  height: 80px;
  // box-shadow: 0px 8px 16px rgba(218, 225, 233, 0.56);
  border-radius: 50%;
  @media (max-width: 767px) {
    width: 88px !important;
    height: 88px !important;
  }
}
@media (min-width: 1440px) {
  .row {
    margin: 0;
    width: 80%;
  }
}
.section1 {
  @media (min-width: 768px) {
    margin-top: 130px !important;
    margin-bottom: 56px !important;
  }
  @media (min-width: 1200px) {
    margin-top: 114px !important;
  }
  @media (max-width: 767px) {
    margin-top: 130px !important;
    margin-bottom: 48px !important;
  }
}

h2 {
  @media (max-width: 767px) {
    max-width: 260px;
    margin: auto !important;
  }
  @media (min-width: 768px) {
  }
  @media (max-width: 767px) {
  }
}
p {
  color: white;
  font-size: 24px;
  font-weight: 400;
  margin: 0 !important;
}
.greenRow {
  @media (max-width: 767px) {
    gap: 64px;
  }
}
</style>
