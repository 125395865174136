<script>
import Hero from "../svg/Hero.vue";
import HeroTablet from "../svg/HeroTablet.vue";
import HeroMobile from "../svg/HeroMobile.vue";
export default {
  components: { Hero, HeroTablet, HeroMobile },
};
</script>

<template>
  <div class="greenBanner">
    <div class="container" style="padding: 0 !important">
      <div class="hero-custom">
        <div class="hero-custom__content">
          <h1 class="hero-custom__title">{{ $t("about-us.hero.title") }}.</h1>
          <h2 class="hero-custom__text hero-subtitle">
            {{ $t("about-us.hero.body") }}
          </h2>
        </div>

        <div class="d-none d-xl-block">
          <Hero />
        </div>
        <div class="d-none d-md-block d-xl-none">
          <HeroTablet style="transform: translate(-120px, 65px)" />
        </div>

        <div class="d-block d-md-none" style="margin-bottom: 62px">
          <HeroMobile />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
h1,
h2 {
  color: white;
}

h1 {
  white-space: normal !important;
  letter-spacing: -0.01em;

  @media (min-width: 1200px) {
    width: 600px !important;
    font-size: 56px !important;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    width: 600px !important;
  }
}
h2 {
  white-space: pre-line;
  @media (min-width: 768px) and (max-width: 1199px) {
    width: 370px !important;
  }
  @media (max-width: 767px) {
    width: 260px !important;
  }
}
.greenBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #07b53b;
  @media (min-width: 768px) {
    height: 600px !important;
  }
}
.hero-custom {
  margin: 0 !important;
}

.hero-custom__content {
  @media (max-width: 767px) {
    margin-left: -3px !important;
  }
}

.hero-custom__text {
  @media (min-width: 1200px) {
    max-width: 430px;
  }
}
</style>
