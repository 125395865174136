var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "313",
      "height": "242",
      "viewBox": "0 0 313 242",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M178.78 232.363C129.678 232.363 89.7479 193.096 88.6783 144.251L233.002 112.971L254.08 154.011C249.181 198.56 217.043 232.363 178.78 232.363Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "5"
    }
  }), _c('path', {
    attrs: {
      "d": "M124.725 140.258L123.205 139.095L121.686 140.258L104.681 153.272L88.6608 141.012C88.9371 103.734 101.729 69.1498 119.373 43.828C137.316 18.0767 159.819 2.5 178.78 2.5C186.542 2.5 195.176 6.24165 203.882 13.387C212.562 20.51 221.12 30.8632 228.678 43.6693C243.792 69.2808 254.726 104.404 254.726 142.243C254.726 145.612 254.567 148.937 254.263 152.212L252.876 153.273L235.868 140.258L234.349 139.095L232.83 140.258L215.824 153.272L198.819 140.258L197.299 139.095L195.78 140.258L178.775 153.272L161.77 140.258L160.251 139.095L158.731 140.258L141.729 153.272L124.725 140.258Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "5"
    }
  }), _c('path', {
    attrs: {
      "d": "M68.4336 145.788H61.3451C59.3877 145.788 57.8008 144.201 57.8008 142.243C57.8008 140.286 59.3877 138.699 61.3451 138.699H68.4336C70.391 138.699 71.9779 140.286 71.9779 142.243C71.9779 144.201 70.391 145.788 68.4336 145.788Z",
      "fill": "#EB653E"
    }
  }), _c('path', {
    attrs: {
      "d": "M76.1291 152.264C74.745 150.881 72.5007 150.881 71.1171 152.264L66.1045 157.277C64.7204 158.661 64.7204 160.905 66.1045 162.289C67.4887 163.673 69.7329 163.673 71.1166 162.289L76.1287 157.277C77.5133 155.893 77.5133 153.649 76.1291 152.264Z",
      "fill": "#EB653E"
    }
  }), _c('path', {
    attrs: {
      "d": "M76.1287 132.219C74.7445 133.603 72.5003 133.603 71.1166 132.219L66.1045 127.207C64.7204 125.822 64.7204 123.578 66.1045 122.194C67.4887 120.81 69.7329 120.81 71.1166 122.194L76.1287 127.207C77.5128 128.591 77.5128 130.834 76.1287 132.219Z",
      "fill": "#EB653E"
    }
  }), _c('path', {
    attrs: {
      "d": "M281.82 145.632H274.732C272.774 145.632 271.188 144.045 271.188 142.087C271.188 140.129 272.774 138.543 274.732 138.543H281.82C283.778 138.543 285.365 140.129 285.365 142.087C285.365 144.045 283.778 145.632 281.82 145.632Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M267.038 152.108C268.422 150.724 270.666 150.724 272.05 152.108L277.062 157.121C278.446 158.505 278.446 160.749 277.062 162.133C275.678 163.517 273.434 163.517 272.05 162.133L267.038 157.121C265.654 155.736 265.654 153.493 267.038 152.108Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M267.038 132.063C268.422 133.447 270.666 133.447 272.05 132.063L277.062 127.05C278.446 125.666 278.446 123.422 277.062 122.038C275.678 120.654 273.434 120.654 272.05 122.038L267.038 127.05C265.654 128.434 265.654 130.678 267.038 132.063Z",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "60.8641",
      "cy": "71.618",
      "r": "5.08219",
      "stroke": "#07B53B",
      "stroke-width": "4.03259"
    }
  }), _c('path', {
    attrs: {
      "d": "M309.339 58.8923C309.339 60.6101 307.946 62.0027 306.228 62.0027C304.51 62.0027 303.118 60.6101 303.118 58.8923C303.118 57.1745 304.51 55.7819 306.228 55.7819C307.946 55.7819 309.339 57.1745 309.339 58.8923Z",
      "stroke": "#07B53B",
      "stroke-width": "4.03259"
    }
  }), _c('circle', {
    attrs: {
      "cx": "98.7959",
      "cy": "233.218",
      "r": "8.73728",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "8.73728",
      "cy": "139.124",
      "r": "8.73728",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M24.0397 199.97C25.438 199.97 26.5715 198.836 26.5715 197.438C26.5715 196.04 25.438 194.906 24.0397 194.906C22.6414 194.906 21.5078 196.04 21.5078 197.438C21.5078 198.836 22.6414 199.97 24.0397 199.97Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M259.27 27.9114C260.668 27.9114 261.802 26.7778 261.802 25.3795C261.802 23.9812 260.668 22.8477 259.27 22.8477C257.872 22.8477 256.738 23.9812 256.738 25.3795C256.738 26.7778 257.872 27.9114 259.27 27.9114Z",
      "fill": "#07B53B"
    }
  }), _c('circle', {
    attrs: {
      "cx": "303.96",
      "cy": "216.589",
      "r": "5.89729",
      "stroke": "#07B53B",
      "stroke-width": "4.67935"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }