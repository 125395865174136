<template>
  <div class="section container">
    <div class="text-center">
      <h2 class="light-theme-title">{{ $t("about-us.how-we-do.title") }}</h2>
      <p class="sub subtitle-bodyLarge text-grey-primary-main-500 roboto">
        {{ $t("about-us.how-we-do.subtitle") }}
      </p>
    </div>
    <div class="container my-box">
      <div class="row align-items-center">
        <div class="my-tick-line col-12 d-flex col-md-6">
          <div class="d-flex align-items-center">
            <TickIcon class="icon" />
          </div>
          <p class="mt-1 subtitle-bodyLarge text-grey-primary-main-500 roboto">
            {{ $t("about-us.how-we-do.item1") }}
          </p>
        </div>
        <div class="my-tick-line col-12 d-flex col-md-6">
          <div class="d-flex align-items-center">
            <TickIcon class="icon" />
          </div>
          <p class="mt-1 subtitle-bodyLarge text-grey-primary-main-500 roboto">
            {{ $t("about-us.how-we-do.item2") }}
          </p>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="my-tick-line col-12 col-md-6 d-flex">
          <div class="d-flex align-items-center">
            <TickIcon class="icon" />
          </div>
          <p class="mt-1 subtitle-bodyLarge text-grey-primary-main-500 roboto">
            {{ $t("about-us.how-we-do.item3") }}
          </p>
        </div>
        <div
          class="d-md-none"
          style="
            height: 3px;
            width: 42.5px;
            background-color: #07b53b;
            margin: auto;
          "
        ></div>
        <div class="my-tick-line col-12 col-md-6 d-flex">
          <div class="d-flex align-items-center">
            <TickIcon class="icon" />
          </div>
          <p class="mt-1 subtitle-bodyLarge text-grey-primary-main-500 roboto">
            {{ $t("about-us.how-we-do.item4") }}
          </p>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="my-tick-line col-12 col-md-6 d-flex">
          <div class="d-flex align-items-center">
            <TickIcon class="icon" />
          </div>
          <p
            class="mt-1 subtitle-bodyLarge text-grey-primary-main-500 d-none d-md-block roboto"
          >
            {{ $t("about-us.how-we-do.item5") }}
          </p>
          <p
            class="mt-1 subtitle-bodyLarge text-grey-primary-main-500 d-md-none roboto"
          >
            {{ $t("about-us.how-we-do.item5b") }}
          </p>
        </div>
        <div class="my-tick-line col-12 col-md-6 d-flex">
          <div class="d-flex align-items-center">
            <TickIcon class="icon" />
          </div>
          <p class="mt-1 subtitle-bodyLarge text-grey-primary-main-500 roboto">
            {{ $t("about-us.how-we-do.item6") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TickIcon from "../svg/desktop-tablet-mobile/TickIcon.vue";
export default {
  components: { TickIcon },
};
</script>

<style lang="scss" scoped>
h2 {
  @media (max-width: 767px) {
    max-width: 197px !important;
    margin: auto !important;
  }
}
.sub {
  margin-top: 24px !important;
}
.section {
  @media (max-width: 767px) {
    margin-top: 80px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 159px !important;
  }
  @media (min-width: 1200px) {
    margin-top: 101px !important;
    .sub {
      margin-bottom: 24px !important;
    }
  }
}
p {
  margin: 0 !important;
  /* identical to box height, or 22px */

  /* Grey/Primary-Main-500 */

  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 120%;
  @media (max-width: 767px) {
    white-space: nowrap;
  }
  @media (max-width: 1199px) {
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    letter-spacing: -0.01em;
  }
}
.my-box {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  @media (max-width: 767px) {
    width: 311px !important;
    margin-top: 25px !important;
    row-gap: 18px !important;
    .row {
      display: flex;
      flex-direction: column;
      row-gap: 18px !important;
    }
  }
  @media (min-width: 768px) and(max-width: 1199px) {
    margin-top: 54px !important;
    row-gap: 49px !important;
    width: 864px;
    margin-left: 20px;
  }
  @media (min-width: 1200px) {
    margin-top: 66px !important;
    row-gap: 32px !important;
    width: 1200px;
    margin-left: 50px;
  }
}

.my-tick-line {
  @media (max-width: 767px) {
    /* margin: 25px auto; */
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
  }
}

.icon {
  margin-right: 8px !important;
}
.container {
  @media (max-width: 767px) {
    padding: 0 !important;
  }
}

.subtitle-bodyLarge {
  white-space: nowrap;
}
</style>
