var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section container"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('h2', {
    staticClass: "light-theme-title"
  }, [_vm._v(_vm._s(_vm.$t("about-us.how-we-do.title")))]), _c('p', {
    staticClass: "sub subtitle-bodyLarge text-grey-primary-main-500 roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.how-we-do.subtitle")) + " ")])]), _c('div', {
    staticClass: "container my-box"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "my-tick-line col-12 d-flex col-md-6"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('TickIcon', {
    staticClass: "icon"
  })], 1), _c('p', {
    staticClass: "mt-1 subtitle-bodyLarge text-grey-primary-main-500 roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.how-we-do.item1")) + " ")])]), _c('div', {
    staticClass: "my-tick-line col-12 d-flex col-md-6"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('TickIcon', {
    staticClass: "icon"
  })], 1), _c('p', {
    staticClass: "mt-1 subtitle-bodyLarge text-grey-primary-main-500 roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.how-we-do.item2")) + " ")])])]), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "my-tick-line col-12 col-md-6 d-flex"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('TickIcon', {
    staticClass: "icon"
  })], 1), _c('p', {
    staticClass: "mt-1 subtitle-bodyLarge text-grey-primary-main-500 roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.how-we-do.item3")) + " ")])]), _c('div', {
    staticClass: "d-md-none",
    staticStyle: {
      "height": "3px",
      "width": "42.5px",
      "background-color": "#07b53b",
      "margin": "auto"
    }
  }), _c('div', {
    staticClass: "my-tick-line col-12 col-md-6 d-flex"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('TickIcon', {
    staticClass: "icon"
  })], 1), _c('p', {
    staticClass: "mt-1 subtitle-bodyLarge text-grey-primary-main-500 roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.how-we-do.item4")) + " ")])])]), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "my-tick-line col-12 col-md-6 d-flex"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('TickIcon', {
    staticClass: "icon"
  })], 1), _c('p', {
    staticClass: "mt-1 subtitle-bodyLarge text-grey-primary-main-500 d-none d-md-block roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.how-we-do.item5")) + " ")]), _c('p', {
    staticClass: "mt-1 subtitle-bodyLarge text-grey-primary-main-500 d-md-none roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.how-we-do.item5b")) + " ")])]), _c('div', {
    staticClass: "my-tick-line col-12 col-md-6 d-flex"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('TickIcon', {
    staticClass: "icon"
  })], 1), _c('p', {
    staticClass: "mt-1 subtitle-bodyLarge text-grey-primary-main-500 roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("about-us.how-we-do.item6")) + " ")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }